/* eslint-disable no-console */
import React from "react"
// @ts-ignore
import FireStoreParser from "firestore-parser"

function App() {
  const [brand, setBrand] = React.useState<string>("")
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [settings, setSettings] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [response, setResponse] = React.useState<any>(null)
  const [completedSteps, setCompletedSteps] = React.useState<string[]>([])

  const textColor = React.useMemo(() => {
    const bgColor = `#${settings?.wallet?.buttonColour}`
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
    const r = parseInt(color.substring(0, 2), 16) // hexToR
    const g = parseInt(color.substring(2, 4), 16) // hexToG
    const b = parseInt(color.substring(4, 6), 16) // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000" : "#FFF"
  }, [settings?.wallet?.buttonColour])

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setLoading(true)

      const data = Object.fromEntries(new FormData(event.currentTarget).entries())
      // @ts-ignore
      try {
        setTimeout(() => setCompletedSteps(prev => [...prev, "saving"]), 500)
        setTimeout(() => setCompletedSteps(prev => [...prev, "creating"]), 1500)

        const response = await fetch("https://litecard-functions.dotdevv.workers.dev", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            shopDomain: brand,
          },
          body: JSON.stringify(data),
        }).then(res => res.json())
        setCompletedSteps(prev => [...prev, "generating"])

        setTimeout(() => {
          setResponse(response)
          setSuccess(true)
          setLoading(false)
        }, 500)
      } catch (err) {
        console.error(err)
        setSuccess(false)
        setLoading(false)
        setCompletedSteps([])
      }
    },
    [brand]
  )

  const getSettings = React.useCallback(async () => {
    const path = window.location.pathname?.replace("/", "")
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/dotapparel-retail/databases/(default)/documents/shops/${path}.myshopify.com`,
      {
        method: "GET",
      }
    )
      .then(res => res.json())
      .then(json => ({ name: json?.name, ...FireStoreParser(json?.fields) }))
    setSettings(response)
    setTimeout(() => setLoaded(true), 500)
  }, [])

  React.useEffect(() => {
    if (!brand) setBrand(window.location.pathname?.replace("/", ""))
    if (!settings) getSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className="h-dvh w-screen bg-black fixed inset-0 flex items-center justify-center transition-opacity"
        style={{
          opacity: loaded ? 0 : 1,
          pointerEvents: loaded ? "none" : "all",
        }}
      >
        <svg className="fill-white absolute animate-spin h-10 w-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M8,1V2.8A5.2,5.2,0,1,1,2.8,8H1A7,7,0,1,0,8,1Z" />
          </g>
        </svg>
      </div>
      <div
        className="h-dvh w-screen bg-black fixed inset-0 flex gap-8 flex-col items-center justify-center text-white transition-opacity"
        style={{
          opacity: loading ? 1 : 0,
          pointerEvents: loading ? "all" : "none",
        }}
      >
        <h2 className="text-2xl font-bold">Hang tight well we generate your pass</h2>
        <p
          className="flex items-center gap-2"
          style={{
            opacity: completedSteps?.includes("saving") ? 1 : 0.5,
          }}
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-white">
            <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
          </svg>
          Saving details...
        </p>
        <p
          className="flex items-center gap-4 transition-opacity"
          style={{
            opacity: completedSteps?.includes("creating") ? 1 : 0.5,
          }}
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-white">
            <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
          </svg>
          Creating pass...
        </p>
        <p
          className="flex items-center gap-4 transition-opacity"
          style={{
            opacity: completedSteps?.includes("generating") ? 1 : 0.5,
          }}
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-white">
            <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
          </svg>
          Generating link...
        </p>
      </div>
      <div
        className="App antialiased flex flex-col gap-4 justify-center items-center h-dvh "
        style={{
          backgroundColor: `#${settings?.wallet?.backgroundColour}`,
          color: `#${settings?.wallet?.textColour}`,
        }}
      >
        {settings?.wallet?.logo && <img src={settings?.wallet?.logo} className="h-12 w-auto block mb-4" />}
        {success ? (
          <div className="flex flex-col items-center gap-8">
            <h1 className="text-2xl font-bold">Download your passes</h1>
            <div className="flex flex-row gap-4">
              {response?.appleLink && (
                <a href={response?.appleLink} target="_blank" rel="noreferrer">
                  <img src="/apple.svg" className="h-10 w-auto block" />
                </a>
              )}
              {response?.googleLink && (
                <a href={response?.googleLink} target="_blank" rel="noreferrer">
                  <img src="/google.svg" className="h-10 w-auto block" />
                </a>
              )}
            </div>
            <button onClick={() => setSuccess(false)} className="underline text-base">
              Start again
            </button>
          </div>
        ) : (
          <>
            {settings?.wallet?.title && <h1 className="text-2xl font-bold">{settings?.wallet?.title}</h1>}
            <form id="form" onSubmit={handleSubmit} className="flex flex-col gap-4 max-w-96 min-w-96">
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name*"
                required
                className="block flex-1 rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name*"
                required
                className="block flex-1 rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Email Address*"
                className="block flex-1 rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                className="block flex-1 rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
              <div className="flex flex-row items-start justify-start gap-2">
                <input
                  type="checkbox"
                  id="emaiMarketing"
                  name="emaiMarketing"
                  className="block rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <label htmlFor="emaiMarketing" className="text-xs">
                  I would like to receive email marketing communications
                </label>
              </div>
              <div className="flex flex-row items-start justify-start gap-2">
                <input
                  type="checkbox"
                  id="smsMarketing"
                  name="smsMarketing"
                  className="block rounded-md border bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <label htmlFor="smsMarketing" className="text-xs">
                  I would like to receive SMS marketing communications
                </label>
              </div>
              <button
                type="submit"
                className="btn font-normal flex items-center justify-center"
                style={{
                  color: textColor,
                  backgroundColor: `#${settings?.wallet?.buttonColour}`,
                }}
              >
                <span className={`${loading ? "opacity-0" : "opacity-100"}`}>Get Pass</span>
                {loading && (
                  <svg fill={textColor} className="absolute animate-spin h-5 w-5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M8,1V2.8A5.2,5.2,0,1,1,2.8,8H1A7,7,0,1,0,8,1Z" />
                    </g>
                  </svg>
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </>
  )
}

export default App
